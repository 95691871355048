/* List of colors used for Pixyl's theme*/
:root {
  --brand-darkgrey-color: #3D3B40;
  --brand-lightgrey-color: #444;
  --brand-darkblue-color: #00539E;
  --brand-mediumblue-color: #008FD0;
  --brand-lightblue-color: #6DCFF6;
  --brand-highcontrast-color: white;
}

body {
  background: var(--brand-darkgrey-color);
  color: var(--brand-lightblue-color);
}

span {
  color: var(--brand-lightblue-color);
}

h1, h2, h3, h4, h5, h6 {
  padding-top: 1em;
  color: var(--brand-darkblue-color);
}

h4 {
  text-align: center;
}

h5 {
  text-align: left;
  margin-left: 20%;
}

.txt-align-left {
  margin-left: 20%;
}

p span {
  color: var(--brand-darkblue-color);;
}

img {
  height: 85%; 
  width: 85%;
  object-fit: contain;
}

div.anonymous, div.user {
  display: none;
}

.box-shadow { 
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); 
}

#navbar-item, #email, #group {
  color: var(--brand-lightblue-color);;
}

.navbar-brand {
  padding: 0px;
}

.square {
  padding: 2px;
  background: var(--brand-darkgrey-color);
}

.view-btn {
  color: var(--brand-highcontrast-color);
}

.col-centered {
  display:inline-block;
  float:none;
  text-align: center;
}

#view-3 {
  background: var(--brand-darkgrey-color);
}

.naixt-logo {
  height: 32px;
  width: 150px;
  margin-top: 6px;
}

#pixyl-logo {
  height: 36px;
  cursor: pointer;
  margin-top: 12px;
  margin-bottom: 12px;
}

@media screen and (max-width: 450px) {
  .naixt-logo {width: 100px;}
}

.container.fixed-bottom {
  padding: 0;
}

#select-view,
.mdl-button--raised.mdl-button--colored,
.mdl-button--raised.mdl-button--colored:hover {
  background: var(--brand-darkblue-color);
}

#about {
  margin-top: 64px; 
  text-align: center;
}

#btn-next {
  margin-top: 64px; 
  margin-right: 64px;
  text-align: right;
}

#nav-menu {
  padding-right: 50px;
}

.mobile-image-button {
  color: var(--brand-darkblue-color);
  margin-right: 8px;
  margin-left: 14px;
  margin-top: 4px;
  border-style: solid;
  border-color: var(--brand-lightgrey-color);
}

.is-focused {
  border-width: 1px;
  border-color: var(--brand-darkblue-color);
}

#view {
  margin-top: 16px; 
  text-align: center;
}

#view h1 {
  margin-bottom: 16px;
}

#view1,
.mdl-button--raised.mdl-button--colored,
.mdl-button--raised.mdl-button--colored:hover {
  background: var(--brand-darkblue-color);
  margin-bottom: 200px;
}

.row-centered {
  margin-bottom: 5%;
} 

#question {
  margin-top: 0px;
  margin-bottom: 10px;
  margin-left: -130px;
}

#question h5 {
  color: var(--brand-lightblue-color);
  margin-top: -10px;
}

#seq1 {
  margin-left: 10px;
}

#seq3-1, #seq3-2, #seq3-3 {
  width: 400px;
}

.resp-options {
  padding-left: 50px;
  padding-right: 50px;
  border: 1px solid var(--brand-lightgrey-color);
  margin-left: -16px;
  margin-top: -10px;
  display: none;
}

#yes {
  background-image: url(resources/check32.png);
  background-repeat: no-repeat;
  background-position: center;
  height: 50px;
  margin-bottom: 30px;
  margin-left: 10px;
}

#fatal {
  background-image: url(resources/warning32.png);
  background-repeat: no-repeat;
  background-position: center;
  height: 50px;
  margin-bottom: 30px;
}

#no {
  background: url(resources/cross32.png);
  background-repeat: no-repeat;
  background-position: center;
  height: 50px;
  margin-bottom: 30px;
  margin-left: -41px;
}

#maybe {
  background: url(resources/question32white.png);
  background-repeat: no-repeat;
  background-position: center;
  height: 50px;
  margin-bottom: 30px;
}

.col-xs-2 {
  width: 20px;
}

/* Add a black background color to the top navigation */
.topnav {
  overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color: var(--brand-darkblue-color);
  text-align: center;
  font-weight: bold;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/**********************/
/* TOP NAVIGATION BAR */
/**********************/

/* Change the color of links on hover */
.topnav a:hover {
  color: var(--brand-highcontrast-color);
}

/* Add a color to the active/current link */
.topnav a.active {
  border-bottom-style: solid;
  border-bottom-color: var(--brand-lightblue-color);
  border-bottom-width: 2px;
}

/*****************/
/* LEFT SIDE BAR */
/*****************/

.sidebar {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: var(--brand-darkblue-color);
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: var(--brand-highcontrast-color);
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: var(--brand-lightgrey-color);
}

.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.openbtn {
  background-color: transparent;
  font-size: 20px;
  cursor: pointer;
  color: var(--brand-highcontrast-color);
  border: none;
}

.openbtn:hover {
  background-color: var(--brand-lightgrey-color);
}

#main {
  transition: margin-left .5s;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidebar {padding-top: 15px;}
  .sidebar a {font-size: 18px;}
}

/****************************/
/* DROP DOWN NAVIGATION BAR */
/****************************/

ul, video  {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}

header nav ul {
	display: none;	
}

.btn-menu {
	margin-top: -10px;
	margin-bottom: 2px;
	margin-right: 13px;
	border: 0;
	width: 40px;
	height: 25px;
	background-color: transparent;
	cursor: pointer;
  position: absolute;
  right: 10px;
}

.btn-menu:focus {
	outline: 0px;	
}

.show_menu .btn-menu:focus {
	outline: none;	
}

.btn-menu .text {
	display: block;
	text-indent: 150%;
	white-space: nowrap;
	overflow: hidden;	
}

.btn-menu .bar,
.btn-menu .bar:before,
.btn-menu .bar:after {
	display: block;
	position: absolute;
	width: 40px;
	height: 5px;
	background-color: var(--brand-darkblue-color);
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.btn-menu .bar {
	top: 10px;
	left: 0;	
}
	
.btn-menu .bar:before {
	position: absolute;
	content: '';
	top: 10px;
	left: 0;
}

.btn-menu .bar:after {
	position: absolute;
	content: '';
	top: -10px;	
	left: 0;
}

.show .btn-menu .bar {
	background-color: transparent;	
}

.show .btn-menu .bar:before {
	top: 0;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);	
}

.show .btn-menu .bar:after {
	top: 0;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);	
}

.mobile-nav {
	height: 0;
	overflow: hidden;
  position: absolute;
	top: 60px;
  right:0;
	background-color: var(--brand-darkblue-color);
	border: 1px solid var(--brand-lightblue-color);
	border-top: none;
	border-bottom: none;
	-webkit-transition: height 0.3s ease-in-out;
	transition: height 0.3s ease-in-out;	
}

.mobile-nav a {
	text-decoration: none;
	color: var(--brand-highcontrast-color);
	font-size: 18px;
	text-indent: 10px;
	display: block;
	line-height: 42px;
  border-bottom: 1px solid var(--brand-lightblue-color);
  padding-right: 10px;
}

.mobile-nav a:focus,
.mobile-nav a:hover,
.mobile-nav a:active {
	color: var(--brand-lightblue-color);	
}

.mobile-nav li:first-child a {
	border-top: 1px solid var(--brand-lightblue-color);	
}

.show .mobile-nav {
	height: auto;
}